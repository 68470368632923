import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import GuitarIcon from '../../svg/guitar.png';

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="w-12 mr-3">
          <img src={GuitarIcon} className="h-12" />
        </div>
        L'emozione parla in lingua
      </div>
      <div className="flex mt-4 sm:mt-0 hidden lg:block">
        <AnchorLink className="px-4" href="#corsi">
          Corsi
        </AnchorLink>
        <AnchorLink className="px-4" href="#scuola_infanzia">
          Scuola
        </AnchorLink>
        <AnchorLink className="px-4" href="#ragazzi_universitari">
          Universitari
        </AnchorLink>
        <AnchorLink className="px-4" href="#lavoratori">
          Lavoratori
        </AnchorLink>
        <AnchorLink className="px-4" href="#iniziative">
          Iniziative
        </AnchorLink>
        <AnchorLink className="px-4" href="#partnership">
          Partnership
        </AnchorLink>
        <AnchorLink className="px-4" href="#testimonials">
          Testimonial
        </AnchorLink>
      </div>
    </div>
  </header>
);

export default Header;
